import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import classNames from 'classnames';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import i18n from '../../i18n';
import Common from '../../api/Common';
import FacebookLoginWrapper from '../social/FacebookLoginWrapper';
import FacebookButton from '../social/FacebookButton';
import AppleButton from '../social/AppleButton';
import Utils from '../../utils/utils';
import Analytics from '../../utils/analytics';
import User from '../../models/User';
import EmailVerificationSignup from './EmailVerificationSignup';
import useGTM from '../analytics/useGTM';
import { NAVIGATION_AREAS, NAVIGATION_ACTION, NAVIGATION_TEXT } from '../analytics/analytics-constants';
import { Callout } from '@homeexchange/design';

class Signin extends React.Component {
    static propTypes = {
        cookies: PropTypes.instanceOf(Cookies).isRequired,
        user: PropTypes.instanceOf(User),
        collection: PropTypes.object,
        email: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: window.popup === 'signin',
            email: '',
            password: '',
            validation: null,
            error: null,
            submit: false,
            isFbOrAppleLogin: false,
            isUserLoad: false,
            shouldBlockFbOrAppleSignin: false,
            stepToRecatchFbOrAppleSignin: null,
            isFromCollection: window.location.pathname.indexOf('/collection') != -1,
            trackGTMSignIn: { shouldTrack: false, origin: null },
            showBanner: false
        };
        this.onLogin = null;
        this.onOpenSigninEvent = this.onOpenSigninEvent.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.signup = this.signup.bind(this);
        this.formValidateEmail = this.formValidateEmail.bind(this);
        this.formValidatePassword = this.formValidatePassword.bind(this);
        this.onAppleLogin = this.onAppleLogin.bind(this);
        this.onFacebookLogin = this.onFacebookLogin.bind(this);
        this.handleFbOrAppleLogin = this.handleFbOrAppleLogin.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onSuccessCollection = this.onSuccessCollection.bind(this);
        this.onTrackNavigation = this.onTrackNavigation.bind(this);
    }

    componentDidMount() {
        document.addEventListener('open_signin_popup', this.onOpenSigninEvent);

        if (Utils.getParameter('token_email_verif')) {
            this.setState({
                showBanner: true,
                showModal: true
            });
        }
    }

    componentDidUpdate() {
        if (this.props.user && this.state.trackGTMSignIn.shouldTrack) {
            const { fetchAccount } = useGTM(this.props.user);

            Analytics.trackGTM('SignIn', fetchAccount(null, this.state.trackGTMSignIn.origin));
            this.setState({ trackGTMSignIn: { ...this.state.trackGTMSignIn, shouldTrack: false } });
        }
        if (this.props.user && this.state.isFbOrAppleLogin) {
            const { shouldBlockFbOrAppleSignin, stepToRecatchFbOrAppleSignin } = this.state;

            try {
                this.handleFbOrAppleLogin();
            } catch (error) {
                console.error('Error while tracking Iterable - handleFbOrAppleLogin', error);
            } finally {
                this.onSuccess(shouldBlockFbOrAppleSignin, stepToRecatchFbOrAppleSignin);
            }
        }
        if (this.props.user && this.state.isUserLoad) {
            this.onSuccessCollection();
        }
        if (this.props.email && this.props.email !== this.state.email) {
            this.updateEmail();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('open_signin_popup', this.onOpenSigninEvent);
    }

    updateEmail() {
        const { email } = this.props;
        this.setState({ email });
    }

    onOpenSigninEvent(e) {
        if (e.detail && e.detail.onLogin) {
            this.onLogin = e.detail.onLogin;
        }
        this.onTrackNavigation('modal', NAVIGATION_ACTION.VIEW, null, NAVIGATION_TEXT.SIGN_IN);
        this.open();
    }

    open() {
        this.setState({ showModal: true });
    }

    close() {
        this.setState({
            showModal: false,
            validation: null,
            error: null,
            submit: false
        });
    }

    handleChange(e) {
        let elementValue = e.target.value;
        if (e.target.type === 'checkbox') {
            elementValue = e.target.checked;
        }

        this.setState({
            [e.target.name]: elementValue
        });
    }

    validateField(field, value) {
        if (!value || value === '' || value === null) {
            return { errors: [`${field}_null`] };
        }
    }

    formValidate(fieldsToValidate) {
        const currentErrors = Object.assign({}, this.state.errors);
        let hasError = false;
        fieldsToValidate.forEach((field) => {
            const error = this.validateField(field, this.state[field]);
            currentErrors[field] = error;
            if (error) {
                hasError = true;
            }
            this.setState({ errors: currentErrors });
        }, this);

        return hasError;
    }

    formValidateEmail() {
        this.formValidate(['email']);
    }

    formValidatePassword() {
        this.formValidate(['password']);
    }

    getErrors(field) {
        if (
            this.state.errors &&
            this.state.errors[field] !== undefined &&
            this.state.errors.hasOwnProperty(field) &&
            this.state.errors[field].hasOwnProperty('errors')
        ) {
            return this.state.errors[field].errors;
        }
    }

    getValidationState(field) {
        if (this.getErrors(field)) {
            return 'error';
        }
    }

    renderError(field) {
        const errors = this.getErrors(field);
        if (errors && errors.length > 0) {
            return (
                <HelpBlock>
                    <ul className="list-unstyled">
                        {errors.map((error) => (
                            <li key={error}>{i18n.t(`common:${error}`)}</li>
                        ))}
                    </ul>
                </HelpBlock>
            );
        }
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.formValidate(['email', 'password'])) {
            return false;
        }

        this.setState({
            submit: true,
            validation: null,
            error: null
        });

        const { cookies } = this.props;

        const data = {
            groupKey: cookies.get('group-key')
        };

        Common.login(this.state.email, this.state.password, i18n.language, data).then(
            (response) => {
                const { home_moderation: homeModeration } = response;

                const shouldBlockSignIn = homeModeration ? homeModeration.under_review : false;
                const stepToRecatch = homeModeration ? homeModeration.to_recatch : null;

                this.setState({ trackGTMSignIn: { shouldTrack: true, origin: 'email' } });

                try {
                    Analytics.trackIterable('Login', {
                        email: this.state.email,
                        device: 'web'
                    });
                } catch (error) {
                    console.error('Error while tracking Iterable', error);
                } finally {
                    if (this.onLogin) {
                        this.onLogin().then(() => this.onSuccess(shouldBlockSignIn, stepToRecatch));
                    } else {
                        this.onSuccess(shouldBlockSignIn, stepToRecatch);
                    }
                }
            },
            (error) => {
                let message;
                if (error.responseJSON.includes('email.error.validation')) {
                    sweetAlert({
                        content: <EmailVerificationSignup email={this.state.email} />,
                        closeOnEsc: false,
                        closeOnClickOutside: false,
                        showCloseButton: false,
                        button: false
                    });

                    this.close();
                }

                if (error.status == 401) {
                    message = i18n.t('error:wrong_credentials');
                }
                this.setState({
                    submit: false,
                    validation: 'error',
                    error: message
                });
            }
        );
    }

    signup(e) {
        e.preventDefault();
        this.close();
        this.onTrackNavigation(
            'button',
            NAVIGATION_ACTION.CLICK,
            NAVIGATION_AREAS.SIGN_IN_MODAL,
            NAVIGATION_TEXT.SIGN_UP
        );
        document.dispatchEvent(new CustomEvent('open_signup_popup'));
    }

    handleFbOrAppleLogin() {
        this.setState({
            isFbOrAppleLogin: false,
            shouldBlockFbOrAppleSignin: false,
            stepToRecatchFbOrAppleSignin: null
        });

        // track iterable
        return Analytics.trackIterable('Login', {
            email: this.props.user.get('email'),
            device: 'web'
        });
    }

    onAppleLogin(response) {
        const { cookies } = this.props;

        const data = {
            groupKey: cookies.get('group-key')
        };

        return Common.loginApple(response.payload, data).then(
            (loginResponse) => {
                const { home_moderation: homeModeration } = loginResponse;

                const shouldBlockSignIn = homeModeration ? homeModeration.under_review : false;
                const stepToRecatch = homeModeration ? homeModeration.to_recatch : null;

                this.setState({
                    isFbOrAppleLogin: true,
                    shouldBlockFbOrAppleSignin: shouldBlockSignIn,
                    stepToRecatchFbOrAppleSignin: stepToRecatch,
                    trackGTMSignIn: { shouldTrack: true, origin: 'apple' }
                });
            },
            (error) => {
                const message = Utils.getErrorMessage(error);
                if (error.status == 401) {
                    sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                } else {
                    sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                }

                // Remove loader
                this.setState({
                    sendingRequest: false
                });
            }
        );
    }

    onFacebookLogin(response) {
        const { userID, accessToken } = response.authResponse;
        const { cookies } = this.props;

        const data = {
            groupKey: cookies.get('group-key')
        };

        return Common.loginFacebook(userID, accessToken, i18n.language, data).then(
            (loginResponse) => {
                const { home_moderation: homeModeration } = loginResponse;

                const shouldBlockSignIn = homeModeration ? homeModeration.under_review : false;
                const stepToRecatch = homeModeration ? homeModeration.to_recatch : null;

                this.setState({
                    isFbOrAppleLogin: true,
                    shouldBlockFbOrAppleSignin: shouldBlockSignIn,
                    stepToRecatchFbOrAppleSignin: stepToRecatch,
                    trackGTMSignIn: { shouldTrack: true, origin: 'facebook' }
                });
            },
            (error) => {
                const message = Utils.getErrorMessage(error);
                if (error.status == 401) {
                    sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                } else {
                    sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                }

                // Remove loader
                this.setState({
                    sendingRequest: false
                });
            }
        );
    }

    onSuccess(shouldBlockSignIn = false, stepToRecatch = null) {
        if (shouldBlockSignIn) {
            window.location.href = '/auth/collection-application-under-review';
        } else if (stepToRecatch && stepToRecatch > 1) {
            const href = window.location;
            if (window.location.pathname.match('/collection')) {
                if (window.location.pathname.match('/propose-my-home')) {
                    if (window.location.href.includes('step')) {
                        window.location.href = `${window.location.href.substr(
                            0,
                            window.location.href.lastIndexOf('/') + 1
                        )}step${stepToRecatch}`;
                    } else {
                        window.location.href = `${href}/step${stepToRecatch}`;
                    }
                } else {
                    window.location.href = `${href}/propose-my-home/step${stepToRecatch}`;
                }
            } else {
                window.location.href = `collection/propose-my-home/step${stepToRecatch}`;
            }
        } else {
            const paymentPageRedirect = Utils.getParameter('subscribe');
            if (paymentPageRedirect) {
                document.location.assign(i18n.t('url:subscription_payment'));
            } else if (window.location.pathname.match('/collection')) {
                this.setState({ isUserLoad: true });
            } else {
                // refresh url or redirect if needed
                const redirectTo = Utils.getParameter('redirectTo');
                if (redirectTo) {
                    document.location.assign(redirectTo);
                } else if (window.location.href.match(/\/p\//)) {
                    // redirect to dashboard if logging from LP.
                    window.location.href = '/dashboard';
                } else {
                    document.location.reload();
                }
            }
        }
    }

    onSuccessCollection() {
        const { user, collection } = this.props;

        if (user && user.get('is_he_collection')) {
            if (collection && collection.redirectToMyPlan) {
                document.location.assign(i18n.t('url:my-plan.url'));
            } else if (collection && collection.redirectToSearch) {
                document.location.assign(i18n.t('search:search_url', { slug: 'everywhere?luxury=true' }));
            } else {
                document.location.assign(i18n.t('url:dashboard'));
            }
        } else {
            document.location.assign(i18n.t('url:dashboard'));
        }
    }

    onTrackNavigation(event, action, area, text) {
        const { fetchNavigation } = useGTM(this.props.user);
        Analytics.trackGTM(event, fetchNavigation(action, area, text));
    }

    render() {
        return (
            <Modal dialogClassName="modal-login" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t('common:signin')}</Modal.Title>
                    <span className="separator"></span>
                </Modal.Header>
                <Modal.Body>
                    <FacebookLoginWrapper
                        onLogin={this.onFacebookLogin}
                        onClick={() =>
                            this.onTrackNavigation(
                                'button',
                                NAVIGATION_ACTION.CLICK,
                                NAVIGATION_AREAS.SIGN_IN_MODAL,
                                NAVIGATION_TEXT.SIGN_IN_FACEBOOK
                            )
                        }
                    >
                        <FacebookButton block={true} size="large" />
                    </FacebookLoginWrapper>
                    <AppleButton
                        onLogin={this.onAppleLogin}
                        text="signin"
                        onClick={() =>
                            this.onTrackNavigation(
                                'button',
                                NAVIGATION_ACTION.CLICK,
                                NAVIGATION_AREAS.SIGN_IN_MODAL,
                                NAVIGATION_TEXT.SIGN_IN_APPLE
                            )
                        }
                    />
                    {/* <GoogleLogin handleConnected={this.handleGoogleConnect} /> */}
                    <span className="separator" data-label={i18n.t('common:or')}></span>
                    <form method="post" onSubmit={this.onSubmit}>
                        {(() => {
                            if (this.state.error) {
                                return (
                                    <p className="text-danger text-center">
                                        {i18n.t(`error:${this.state.error}`)}
                                    </p>
                                );
                            }
                        })()}
                        <FormGroup
                            controlId="email"
                            bsSize="large"
                            validationState={this.getValidationState('email')}
                        >
                            {this.state.showBanner && (
                                <Callout hasIcon size="medium" status="success">
                                    <span>{i18n.t('messaging:signin-success-callout')}</span>
                                </Callout>
                            )}
                            <ControlLabel srOnly>{i18n.t('user:email')}</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    placeholder={i18n.t('user:email')}
                                    onChange={this.handleChange}
                                    onBlur={this.formValidateEmail}
                                />
                                <ControlLabel htmlFor="email" bsClass="input-group-addon">
                                    <InputGroup.Addon bsClass="">
                                        <Glyphicon glyph="envelope" />
                                    </InputGroup.Addon>
                                </ControlLabel>
                            </InputGroup>
                            {this.renderError('email')}
                        </FormGroup>
                        <FormGroup
                            controlId="password"
                            bsSize="large"
                            validationState={this.getValidationState('password')}
                        >
                            <ControlLabel srOnly>{i18n.t('user:password')}</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    placeholder={i18n.t('user:password')}
                                    onChange={this.handleChange}
                                    onBlur={this.formValidatePassword}
                                />
                                <ControlLabel htmlFor="password" bsClass="input-group-addon">
                                    <InputGroup.Addon bsClass="">
                                        <Glyphicon glyph="lock" />
                                    </InputGroup.Addon>
                                </ControlLabel>
                            </InputGroup>
                            {this.renderError('password')}
                            <div className="text-block">
                                <a href={i18n.t('url:forgot_password')}>{i18n.t('user:reset_password')}</a>
                            </div>
                        </FormGroup>
                        <Button
                            type="submit"
                            block
                            bsStyle="primary"
                            bsSize="large"
                            className={classNames('btn-ajax', {
                                sending: this.state.submit
                            })}
                            disabled={this.state.submit}
                            onClick={() =>
                                this.onTrackNavigation(
                                    'button',
                                    NAVIGATION_ACTION.CLICK,
                                    NAVIGATION_AREAS.SIGN_IN_MODAL,
                                    NAVIGATION_TEXT.SIGN_IN_EMAIL
                                )
                            }
                        >
                            {i18n.t('common:connect')}
                        </Button>
                    </form>
                    {window.location.pathname !== '/collection/propose-my-home' &&
                        window.location.pathname !== '/collection/propose-my-home/step1' && (
                            <>
                                <span className="separator"></span>
                                <div className="signup-footer">
                                    {i18n.t('common:no_account_yet')}
                                    <a
                                        className="m-l-10"
                                        href={
                                            this.state.isFromCollection ? 'collection/propose-my-home' : '#'
                                        }
                                        {...(!this.state.isFromCollection && {
                                            onClick: this.signup
                                        })}
                                    >
                                        {i18n.t('common:create_account')}
                                    </a>
                                </div>
                            </>
                        )}
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    collection: state.collection
});

export default compose(connect(mapStateToProps), withCookies)(Signin);
